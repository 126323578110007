export const DocumentConst = {
    vatPaymentRefDateType: {
        DOC_ADJ_DUE_DATE: 'DUE_DATE',
        DOC_ADJ_DOC_DATE: 'DOC_DATE',
        DOC_ADJ_OPS_DATE: 'OPS_DATE'
    },
    
    ediMonitoringStatus: {
        EDI_SENT: 'Sent',
        ACK_RECEIVED: 'Received',
        EDI_PENDING: 'Pending',
    },

    issueType: {
        ISSUE_TYPE_EDI: 'EDI',
        ISSUE_TYPE_HARD_COPY: 'HARDCOPY',
        ISSUE_TYPE_SOFT_COPY: 'SOFTCOPY',
        ISSUE_TYPE_EMAIL: 'EMAIL',
        ISSUE_TYPE_E_INVOICE: 'E-INVOICE',
        ISSUE_TYPE_P_PRINT: 'P-PRINT',
    },

    docuemtApprovalStatus: {
        NA: 'Not available',
        WFA: 'Wait for approval',
        APP: 'Approved',
        REJ: 'Disapproved',
    },

    billingTypePolicy: {
        1: 'Ship Bounded',
        2: 'Ship Bounded in Period',
        3: 'Domestic',
    },

    billingTypeForSOAPolicy: {
        SHIP: 'Ship Bounded & Ship Bounded In Period',
        DOMESTIC: 'Domestic',
    },

    ediExportType: {
        Y: 'EDI',
        N: 'non-EDI',
    },

    ediItemCodeTypePolicy: {
        EDI_ITEM_CODE: 'EDI Item Code',
        RECOMMENDATION23: 'Recommendation23',
    },
    docType:{
        IN: 'IN',
        CN: 'CN',
    },
    
    collectionTypePolicy:{
        DEBIT: 'DEBIT',
        CREDIT: 'CREDIT',
    },

    reqFormState: {        
        NIL: "NIL",
        INV: "INV",        
    },

    referenceType: {
        LETTER: "Letter",
        FAX: "Fax",
        MEMO:"Memo",
        EMAIL:"E-Mail"
    },

    consolMethodPolicy: {
        CON_BY_ORI_INVOICE: 'By Original Invoice',
        CON_BY_BILL_TO_COMPANY: 'By Bill To Company'
    },
}