import { RowModelType, type GridReadyEvent, type RowDataUpdatedEvent, type SelectionChangedEvent } from "ag-grid-enterprise";

export interface TableQuickSearchProps {
    quickSearchLabel?: string;
    quickSearchType?: 'typeSearch' | 'clickSearch';
    width?: string;
}

export interface NbisTableProps {
    id?: string;
    row?: number;
    data?: any[];
    columns?: any[];
    selectionMode?: boolean;
    editable?: boolean;
    editMode?: 'row' | 'cell';
    showDeleteButton?: boolean;
    showActionButtons?: boolean;
    showActionColumn?: boolean;
    showPaginator?: boolean;
    showHeaderIcons?: boolean;
    addButtonId?: any;
    onAddClick?: any;
    customHeight?: any;
    gridHeight?: 'autoHeight' | 'customHeight' | 'fullHeight';
    headerLabel?: any;
    onRowDoubleClick?: any;
    onRowClick?: any;
    isRowChange?: boolean;
    isRowHighligted?: boolean;
    showReloadIcon?: boolean;
    showUploadIcon?: boolean;
    showAddIcon?: boolean;
    isScrollHighlighted?: boolean;
    isRowDrag?: boolean;
    onRowDragged?: (e: any, updatedRows: any[], movedIndex: number, overIndex: number) => void;
    onSave?: (e: any) => void;
    onRowDataUpdated?: (event: RowDataUpdatedEvent<any, any>) => void;
    isRowSelectable?: (params: any) => boolean;
    showSidebar?: boolean;
    initialState?: any;
    isNewColumnSetting?: boolean;
    columnSettingIconList?: JSX.Element[];
    rowModelType?: RowModelType;
    dataSource?: () => void;
    cacheBlockSize?: number;
    showQuickSearch?: boolean;
    quickSearch?: TableQuickSearchProps;
    showStatusBar?: boolean;
    autoMarginLeft?: boolean;
    entrypoint?: string;
    showPreferenceMenu?: boolean;
    onGridReady?: (params: GridReadyEvent) => void;
    onSelectionChanged?: (params: SelectionChangedEvent) => void;
    mustRefresh?: boolean;

    [x: string]: any;
}

export const defaultNbisTableProps: NbisTableProps = {
    showActionColumn: true,
    showActionButtons: true,
    gridHeight: 'autoHeight',
    editMode: 'cell',
    selectionMode: false,
    editable: false,
    showDeleteButton: true,
    showPaginator: true,
    showHeaderIcons: true,
    showUploadIcon: true,
    isRowChange: false,
    isRowHighligted: true,
    isScrollHighlighted: true,
    isRowDrag: false,
    showSidebar: true,
    isNewColumnSetting: true,
    columnSettingIconList: [],
    rowModelType: 'clientSide',
    rowSelection: 'single',
    showQuickSearch: false,
    showStatusBar: true,
    quickSearch: { quickSearchLabel: 'Quick Search', quickSearchType: 'typeSearch', width: '25%' },
    showPreferenceMenu: true,
    mustRefresh: false,
};